<template>
  <div class="popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-12 p-5">
        <div class="popup_inner">
          <div class="overlay"></div>
          <div class="row mx-0 above">
            <div class="col-12 p-0">
              <ios-close-icon class="inline-icon big cursor close" @click="$emit('close')"/>
              <div class="row p-5">
                <div class="col-12 mb-5">
                  <div class="row h-100">
                    <div class="col-lg-5" ref="theImage">
                      <div v-if="projectGallery" class="h-100">
                        <Carousel :images="gallery" v-if="isReady"/>
                      </div>
                      <Image :imageName="project.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="img-fluid fill" v-else />
                      <!-- <img :src="url + '/static/uploads/images/' + project.imageUrl" class="img-flud fill" v-else /> -->
                    </div>
                    <div class="col-lg-7">
                      <div class="row m-0">
                        <div class="col-12 heading mb-3">
                          {{project.name}} <span v-if="project.location"> - {{project.location.country.name}}</span>
                        </div>
                      </div>
                      <div class="row m-0">
                        <div class="col-12">
                          <ProjectDonate :project="project" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-lg-7">
                      <div v-html="project.description"></div>
                    </div>
                    <div class="col-lg-5 border-start">
                      <div class="row mx-0 mb-3">
                        <div class="col-12 smallheading">
                          Project Updates
                        </div>
                        <div class="col-6 my-3" v-for="project in projects.slice(0, 4)" :key="project.id">
                          <ProjectSmallCard  :project="project" />
                        </div>
                      </div>
                      <div class="row mx-0">
                        <div class="col-12 smallheading">
                          Related Appeals
                        </div>
                        <div class="col-6 my-3" v-for="project in projects.slice(4, 8)" :key="project.id">
                          <ProjectSmallCard  :project="project" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Carousel: defineAsyncComponent(() => import('../components/Carousel.vue')),
    Image: defineAsyncComponent(() => import('../components/Image.vue')),
    ProjectDonate: defineAsyncComponent(() => import('./ProjectDonate.vue')),
    ProjectSmallCard: defineAsyncComponent(() => import('./ProjectSmallCard.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Project Popup',
  props: ['project'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      isReady: false,
      gallery: [],
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  async created () {
    await this.fetchGalleries(this.project.id)
  },
  beforeUpdate () {
    this.setGallery()
  },
  beforeUnmount () {
    this.clearGallery()
  },
  computed: {
    ...mapGetters([
      'projects',
      'projectGallery'
    ])
  },
  methods: {
    ...mapActions([
      'fetchGalleries'
    ]),
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    setGallery () {
      if (this.projectGallery) {
        this.gallery.push(this.project)
        this.projectGallery[0].images.forEach(image => {
          this.gallery.push(image)
        })
      }
      this.isReady = true
    },
    clearGallery () {
      this.gallery = []
    }
  }
}
</script>

<style scoped>
  .fill {
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
  }
  .popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    z-index: 99;
  }
  .popup_inner  {
    animation-duration: 1.2s;
    border-radius: 22px 22px 22px 22px;
    box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
    margin: 0;
    max-height: 80vh;
    overflow: hidden;
    background-color: #FFFFFF;
    background-image: url(../assets/images/bg.png);
    position: relative;
    padding: 1rem;
  }
  .overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFFFFFF7 0%, #FFFFFF 100%);
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .above {
    position: relative;
    z-index: 2;
    color: var(--normal-color-dark);
    max-height: calc(80vh - 2rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .heading {
    color: var( --green-color-dark );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 44px;
    font-weight: 600;
    line-height: 1.5em;
  }
  .smallheading {
    color: var( --green-color );
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .close {
    position: fixed;
    top: 12vh;
    right: 5rem;
    color: var( --green-color );
    font-size: 3rem;
  }
</style>
